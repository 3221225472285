import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.hide();
  }

  show() {
    this.menuTarget.classList.add("show");
  }

  hide() {
    this.menuTarget.classList.remove("show");
  }
}
