import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    create: Boolean,
    loadUrl: String,
    maxItems: Number
  }

  connect() {
    const options = {
      plugins: ['dropdown_input', 'remove_button', 'clear_button'],
      create: this.createValue,
      sortField: { field: "text" },
      maxItems: this.maxItemsValue || 1,
      // Si se proporciona una URL para cargar, configurar la opción de carga
      classNames: {
        // ...TomSelect.defaults.classNames,
        input: 'custom-tom-select-input' // Clase personalizada para el input
      }
    };

    if (this.loadUrlValue) {
      options.load = (query, callback) => {
        if (!query.length) return callback();
        fetch(`${this.loadUrlValue}?query=${encodeURIComponent(query)}`)
          .then(response => response.json())
          .then(json => {
            const results = Array.isArray(json) ? json : [json];
            const transformed = results.map(item => ({ value: item.value, text: item.value }));
            callback(transformed);
          })
          .catch(() => callback());
      };
    }

    try {
      new TomSelect(this.element, options);
    } catch (e) {
      console.warn("TomSelect ya fue inicializado en este elemento", e);
    }
  }
}
