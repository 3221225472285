import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["amountField"]

  updateServiceIds(event) {
    const selectedIds = Array.from(event.target.selectedOptions).map(option => option.value);
    this.fetchAmount(selectedIds);
  }

  fetchAmount(selectedIds) {
    const url = "/transactions/calculate_amount";
    const params = { service_ids: selectedIds };

    fetch(`${url}?${new URLSearchParams(params)}`)
      .then((response) => response.json())
      .then((data) => {
        this.updateAmount(data);
      })
      .catch((error) => console.error("Error fetching amount:", error));
  }

  updateAmount(data) {
    const totalAmount = data.total || 0;
    this.amountFieldTarget.value = totalAmount;
  }
}
