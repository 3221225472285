import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element);
    this.modal.show();
    Inputmask().mask(document.querySelectorAll("input"));

    this.element.addEventListener("close-modal", () => this.close());
  }

  close() {
    let modalElement = document.querySelector("#modal");
    let modalInstance = bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
    if (this.modal) {
      this.modal.hide();
    }
    this.element.remove(); // Elimina el elemento del DOM
  }

  disconnect() {
    if (this.modal) {
      this.modal.hide();
    }
    this.element.remove();
  }
}
