// app/javascript/controllers/member_list_controller.js
import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modalContent"];

  connect() {
    this.modal = new Modal(document.getElementById("memberListModal"));
  }

  openModal() {
    this.loadMemberList();
    this.modal.show();
  }

  loadMemberList() {
    fetch("/members")
      .then((response) => response.text())
      .then((html) => {
        this.modalContentTarget.innerHTML = html;
      })
      .catch((error) => {
        console.error("Error al cargar la lista de socios:", error);
      });
  }
}
