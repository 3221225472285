import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card", "type", "icon"];
  static values = { types: Object };

  connect() {
    this.initializeBackgroundAndIcon(); // Inicializa el fondo y el ícono al conectar
  }

  updateType(event) {
    const selectedType = event.target.value;
    const typeConfig = this.typesValue[selectedType];

    if (typeConfig) {
      const { color, icon } = typeConfig;

      this.updateBackground(color); // Actualiza el fondo
      this.updateIcon(icon); // Actualiza el ícono
    } else {
      // Resetea a un estado por defecto si no hay tipo seleccionado
      this.resetBackgroundAndIcon();
    }
  }

  initializeBackgroundAndIcon() {
    const typeConfig = this.typesValue[this.typeTarget.value];
    if (typeConfig) {
      const { color, icon } = typeConfig;
      this.updateBackground(color);
      this.updateIcon(icon);
    } else {
      this.resetBackgroundAndIcon();
    }
  }

  updateBackground(color) {
    if (!color) return;

    this.cardTarget.className = this.cardTarget.className
      .split(" ")
      .filter(cls => !cls.startsWith("bg-"))
      .join(" ");

    this.cardTarget.classList.add(color);
  }

  updateIcon(iconClass) {
    if (!iconClass) return;

    this.iconTarget.className = ""; // Limpia las clases previas
    this.iconTarget.classList.add(...iconClass.split(" ")); // Añade las nuevas clases del ícono
  }

  resetBackgroundAndIcon() {
    // Resetea el fondo a un estado predeterminado (por ejemplo, "bg-light")
    this.updateBackground("bg-light");

    // Resetea el ícono a vacío o a un estado predeterminado
    this.iconTarget.className = "";
  }
}
